.table-cell-kcal {
    white-space: nowrap;
}
.table-cell-weight {
    white-space: pre-line;
}
.food-entry-row {
    height: 25px !important;
}
.food-entry-row > td {
    border-bottom: 1px dashed #efebe9;
}

.food-entry-row.is-expanded > td {
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.07);
}

.food-entry-row.is-expanded > td:first-child {
    border-left: 1px dashed #efebe9;
}
.food-entry-row.is-expanded > td:last-child {
    border-right: 1px dashed #efebe9;
}
.food-entry-row-expansion > td {
    border-left: 1px dashed #efebe9;
    border-right: 1px dashed #efebe9;
    border-bottom: 1px dashed #efebe9;
}