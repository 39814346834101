.loading-spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loading-spinner-container.fixed {
    position: fixed;
}
.loading-spinner-container.hide {
    opacity: 0;
}
.loading-spinner-message {
    margin-top: 20px;
    background-color: white;
}