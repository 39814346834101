.timeslot {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 600px;
    margin-top: 5px;
}
.timeslot .recharts-wrapper {
    display: inline-block;
}
.timeslot .timeslot-table-head-row {
    height: 30px;
    cursor: crosshair;
}